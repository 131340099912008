'use client'

// React Imports
import React, {useEffect, useState} from 'react'

// Next Imports

// MUI Imports
import {styled} from '@mui/material/styles'
import Fade from '@mui/material/Fade';

// Third-party Imports
import classnames from 'classnames'

// Type Imports
import type {SystemMode} from '@core/types'

// Component Imports

// Config Imports
import themeConfig from '@configs/themeConfig'

// Hook Imports
import {useSettings} from '@core/hooks/useSettings'

// Util Imports
import {useTranslation} from 'react-i18next';

import LoginForm from '@components/login-form/LoginForm'
import RecoverPassword from '@components/recover-password/RecoverPassword'
import {useParams, useRouter} from "next/navigation";
import type {Locale} from "@configs/i18n";
import {getDictionary} from "@/utils/getDictionary";
import LanguageDropdown from "@components/layout/shared/LanguageDropdown";
import ModeDropdown from "@components/layout/shared/ModeDropdown";


// Styled Custom Components
const LoginIllustration = styled('img')(({theme}) => ({
    zIndex: 2,
    width: '100%',
    // heigth: 'auto',
    blockSize: 'auto',
    // maxBlockSize: 680,
    //maxInlineSize: '100%',
    // margin: theme.spacing(0),
    // [theme.breakpoints.down(1536)]: {
    //     maxBlockSize: 550
    // },
    // [theme.breakpoints.down('lg')]: {
    //     maxBlockSize: 450
    // }
}))

interface Props {
    mode: SystemMode;
}


const Login: React.FC<Props> = ({mode}) => {
    const router = useRouter()

    // States
    const [switchForm, setSwitchForm] = useState(false)
    const [imageLoaded, setImageLoaded] = useState(false);

    // Vars
    const loginImg = '/images/illustrations/login.png'

    const {lang: locale}: any = useParams()
    const [t, setTranslations]: any = useState({})
    useEffect(() => {
        getDictionary(locale as Locale).then(dictionary => {
            setTranslations(dictionary)
        })

        // const preloadImage = new Image();
        // preloadImage.src = loginImg; // Pre-carica l'immagine
        // preloadImage.onload = () => setImageLoaded(true);
        // preloadImage.onerror = () => console.error('Error loading image');
    }, []);

    function translate(key: string) {
        return t[locale][key] || key
    }


    const {settings} = useSettings()
    const handleSwitch = (value: boolean) => {
        setSwitchForm(value)
    }


    const [windowWidth, setWindowWidth] = useState(() => {
        // Controlla se window è definito prima di accedervi
        if (typeof window !== 'undefined') {
            return window.innerWidth;
        }
        return 0; // valore predefinito se non siamo nel browser
    });
    useEffect(() => {
        // Verifica se window è disponibile prima di aggiungere un listener
        if (typeof window !== 'undefined') {
            const handleResize = () => {
                setWindowWidth(window.innerWidth);
            };

            window.addEventListener('resize', handleResize);
            // Pulizia del listener
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);


    return (t?.[locale] &&
        //imageLoaded &&
        <Fade in timeout={300}>
            <div className='flex bs-full' style={{overflow: 'hidden'}}>
                <div style={{height: "500px", overflow: 'hidden'}}
                     className={classnames(
                         'flex bs-full flex-1 min-bs-[100dvh] relative max-md:hidden',
                         {
                             'border-ie': settings.skin === 'bordered'
                         }
                     )}
                >
                    <LoginIllustration src={loginImg} alt='img-sfondo'/>
                </div>


                {windowWidth <= 1250 ? (
                    <div
                        className={'background-svg-login'}
                        style={{
                            width: "100%",
                            height: '100%',
                            objectFit: "fill",
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '25px'
                        }}
                    >
                        <div style={{overflow: 'hidden', width: '100%'}}
                             className={`bg-backgroundPaper !min-is-full p-6 md:!min-is-[unset] md:p-12`}>

                            <div className='flex flex-row-reverse end'>
                                <ModeDropdown/>
                                <LanguageDropdown/>
                            </div>

                            <div className='flex justify-center items-center'>
                                {!switchForm ? (
                                    <LoginForm handleSwitch={handleSwitch} t={translate}/>
                                ) : (
                                    <RecoverPassword handleSwitch={handleSwitch} t={translate}/>
                                )}
                            </div>

                        </div>
                    </div>
                ) : (
                    <div style={{overflow: 'hidden', width: '40%'}}
                        className={`flex flex-col justify-center bs-full bg-backgroundPaper !min-is-full p-6 md:!min-is-[unset] md:p-12 md:is-[1080px]`}>
                        <div style={{width: 'auto'}}>
                            <img width={'35%'} style={{maxWidth: '280px', minWidth: "280px"}} alt={'test'}
                                 src={'/images/logo.svg'}/>
                        </div>


                        <div style={{width: '100%'}}
                             className={`flex flex-col justify-center bs-full bg-backgroundPaper !min-is-full p-6 md:!min-is-[unset] md:p-12 md:is-[1080px]`}>

                            <div className='flex flex-row-reverse end'>
                                <ModeDropdown/>
                                <LanguageDropdown/>
                            </div>

                            <div className='flex flex-col justify-center items-center'>
                                {!switchForm ? (
                                    <LoginForm handleSwitch={handleSwitch} t={translate}/>
                                ) : (
                                    <RecoverPassword handleSwitch={handleSwitch} t={translate}/>
                                )}
                            </div>

                        </div>
                    </div>
                )}

            </div>
        </Fade>
    )
}

export default Login
